import vacancySearchContactsSignupButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_contacts_signup_button_click';
import { Text, Button as MagritteButton, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { FormItem } from 'bloko/blocks/form';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    register: 'registration.form.register.self',
    contactAfterRegistration: 'registration.action.contactsAfterRegistration',
};

type RegistrationActionProps = {
    vacancyId: number;
    isMagritte?: boolean;
};

const RegistrationAction: TranslatedComponent<RegistrationActionProps> = ({ trls, vacancyId, isMagritte }) => {
    const signupUrl = useSelectorNonNullable((state) => state.authUrl?.['signup-form']);

    if (isMagritte) {
        return (
            <>
                <Text typography="label-1-regular">{trls[TrlKeys.contactAfterRegistration]}</Text>
                <MagritteVSpacing default={24} />
                <MagritteButton
                    mode="primary"
                    style="accent"
                    Element={Link}
                    to={signupUrl}
                    size="small"
                    onClick={() => vacancySearchContactsSignupButtonClick({ vacancyId })}
                    stretched
                >
                    {trls[TrlKeys.register]}
                </MagritteButton>
            </>
        );
    }

    return (
        <div>
            <VSpacing base={2} />
            <FormItem>{trls[TrlKeys.contactAfterRegistration]}</FormItem>
            <FormItem>
                <Button
                    Element={Link}
                    to={signupUrl}
                    kind={ButtonKind.Primary}
                    scale={ButtonScale.Small}
                    onClick={() => vacancySearchContactsSignupButtonClick({ vacancyId })}
                >
                    {trls[TrlKeys.register]}
                </Button>
            </FormItem>
        </div>
    );
};

export default translation(RegistrationAction);
