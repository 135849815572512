import { useRef } from 'react';

import { TooltipHover, Text } from '@hh.ru/magritte-ui';
import { VerifiedBadgeFilledSize16, VerifiedBadgeFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { TopLevelSite } from 'lux/models/topLevelSite';
import { useSelector } from 'lux/modules/useSelector';

import styles from './trusted-employer-check.less';

const TrlKeys = {
    trustedEmployer: 'tooltip.checked.employer',
};

export enum TrustedEmployerCheckSize {
    Small = 'small',
    Large = 'large',
}

interface TrustedEmployerCheckProps {
    size: TrustedEmployerCheckSize;
    isMagritte?: boolean;
}

const getCheckedEmployerFaqLink = (site: TopLevelSite): string => {
    switch (site) {
        case TopLevelSite.UA:
            return '/article/20099';
        case TopLevelSite.BY:
            return '/article/19873';
        case TopLevelSite.ZP:
            return '/article/20099';
        default:
            return 'https://feedback.hh.ru/article/details/id/5951';
    }
};

const TrustedEmployerCheck: TranslatedComponent<TrustedEmployerCheckProps> = ({ trls, size, isMagritte }) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const topLevelSite = useSelector(({ topLevelSite }) => topLevelSite);

    const icon = (
        <span className={styles.trustedEmployerCheckIcon} ref={activatorRef}>
            {size === TrustedEmployerCheckSize.Small && <VerifiedBadgeFilledSize16 initial="accent" />}
            {size === TrustedEmployerCheckSize.Large && <VerifiedBadgeFilledSize24 initial="accent" />}
        </span>
    );

    return isMagritte ? (
        <Link target="_blank" to={getCheckedEmployerFaqLink(topLevelSite)} data-qa="trusted-employer-link">
            <>
                {icon}
                <TooltipHover placement="top-center" activatorRef={activatorRef}>
                    <Text>{trls[TrlKeys.trustedEmployer]}</Text>
                </TooltipHover>
            </>
        </Link>
    ) : (
        <BlokoLink
            Element={Link}
            target="_blank"
            to={getCheckedEmployerFaqLink(topLevelSite)}
            data-qa="trusted-employer-link"
        >
            <HoverTip
                render={() => trls[TrlKeys.trustedEmployer]}
                placement={TipPlacement.Top}
                layer={TipLayer.Overlay}
                host={!process.env.LUX_SERVER ? document.body : null}
            >
                {icon}
            </HoverTip>
        </BlokoLink>
    );
};

export default translation(TrustedEmployerCheck);
